.typeTagInputWrapper {
    width:100%;
    align-items: center;
    padding: 2%;    
}

.typeTagCloud {
    width:100%;
}

.typeTagInputLabel {
    font-weight: 900;
}

.typeTagInput {
        margin-right:auto;
        margin-left:auto;
        border-radius: 20px;
        padding: 1%;        
        width:80%;
        align-items: center;
    }

true {
    display:block;
}

false {
    display: none;
}