* {
    font-family: Helvetica;
    margin:0;
    padding:0;
}

.pageContainer {
    text-align: center;
    align-items: center;
    
    padding: 10px 0 10px 0;
}


h2 {
    clear:both;
}

.wrapper {
    
    display: block;
    align-items: center;
    padding: 20px 0 0 0;
    display: flex;
    flex-direction: column;
    position:relative;
}


@media screen and (min-width: 600px) {
    .wrapper {
        
        width: 60%;
        align-items: center;
        padding:  0 15%  0 15%;
        display: flex;
        flex-direction: column;
        position:relative;
    }
  }



ul.menu {
    display: inline-block;
    
    font-size: 1.6vh;
    margin-right:auto;
    margin-left: auto;
    align-items: center;
    border-radius: 30px;
    padding:0%;
    list-style: none;
}



.menuItem {
    margin-left: auto;
    margin-right: auto;
    font-size: 2.2vh;
    color:black;
    text-decoration: none;
    font-weight: 700;
    float:left;
    border-style:solid;
    border-width: 0.4vh;
    background-color: #FBCE44;
    margin: 0.5vw 0.5vw;
    border-radius: 2em;
    padding:0.5em 1em;
}

