.tagInputWrapper {
    width:100%;
    align-items: center;
    padding: 2%;    
}

.tagCloud {
    width:100%;
}

.tagInputLabel {
    font-weight: 900;
}

.tagInput {
        margin-right:auto;
        margin-left:auto;
        border-radius: 20px;
        padding: 1%;        
        width:80%;
        
    }


