.menuButton {
    position: relative;
    margin: 0 auto;
    font-family:Helvetica, Arial, sans-serif;
    font-size: 3vh;
    border-radius: 40px;
    border-style: solid;
    border-width: 3px;
    border-color: black;
    padding: 1vw;
    margin: 1vw 2vw;

    color:black !important;    
    background-color: #13A1C3;
    vertical-align: middle;
    cursor: pointer;    
}

.time {
   /* circle shape, size and position */
  position: absolute;
  right: -0.9em;
  top: -0.9em;
  min-width: 1.6em; /* or width, explained below. */
  height: 1.6em;
  border-radius: 0.8em; /* or 50%, explained below. */
  border: 0.05em solid white;
  background-color: red;

  /* number size and position */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6em;
  padding: 0.5vw 1vw;
  font-weight: bold;
  color: white;
}
.timeEg {
    
    
    padding:1vw 2vw;
    border-radius: 6vw;
    border-width: 1px;
    font-size: 3vw;
    font-weight: 900;
    border-style: solid;
    border-color: black;
    position: relative;
    
    background-color: red;
    color:white;
    
}

.text {
    font-size: 0.6rem;
    vertical-align: super;
}

.menuButton:hover {
    
    font-weight: 900;
    color:white !important;
}

.hidden {
    display:none;
}

.visible {
    display: visible;
}

a {
    text-align: center;
    text-decoration: none !important;
    color:black !important;
}

