p {
    padding: 3% 0 3% 0;
    margin: 1% 0 1% 0;
}

h3 {
    margin: 3% 0 3% 0;
}

.clickedContainer {
    border-width: 2px;
    border-style: solid;
    border-color: black;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.serviceContainer table {
    margin-left: auto;
    margin-right: auto;
}

.serviceContainer th {
    text-align: center;
    
}

.serviceContainer td {
    width:50%;
    text-align: center;
    
}

.serviceContainer {
    align-items: center;
    text-align: center;
    border-radius: 20px;;
    
    
}

.clicked {
    background-color: #FBCE44;
    border-width: 2px;
    border-color: black;
    border-style: solid;
}

.true {
    visibility: visible;
}

.false {
    visibility: hidden;
}

.serviceID {
    border-color: black;
    background-color: #FBCE44;
    border-style: solid;
    padding:0.3em;
    width:1em;
    border-radius: 20px;
    border-width: 1px;
    font-size: 0.5em;
}

.service {
    margin: 1vw 2vw;
       
}

div.service {
 font-size: 1em;
 font-weight: 100;
 
}

div.serviceName {
    font-size: 1.5em;
    font-weight:bold;
    padding: 0 2% 0 2%;
    border-radius: 20px;
    box-shadow: 2px 2px;
    border-style: solid;
    border-width: 0.2px;
    
}

div.servicePhone {
    border-radius: 20px;
    border-style: solid;
    padding: 0 3% 0  3%;
    border-color: black;
    background-color: #FBCE44;
    font-weight: 800;
    border-width:1px;
    padding: 2% 3% 2% 3%;
    display: inline-block;
    font-size: 0.8rem;
    text-decoration: underline;
}

.reviews {
    padding: 3% 0 3% 0;
    margin: 3%;
    background-color: antiquewhite;
    border-radius: 30px;
}

h3.reviews {
    
    margin: 5% 0 0 0;
}
.reviews p {
    padding: 2% 0 2% 0;
    margin: 0 0 0 0;
}

div.serviceWebsite {
    border-radius: 20px;
    border-style: solid;
    padding: 2% 3% 2% 3%;
    border-color: black;
    background-color: #FBCE44;
    font-weight: 800;
    border-width:1px;
    font-size: 0.8rem;
    clear:left;
    display: inline-block;
    text-decoration: underline;
}

div.serviceType {
    font-size: 0.8em;
    font-weight: 900;
}

div.serviceDescription {
    clear:both;
}



serviceInfo {
    width:20px;
}




