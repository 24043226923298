.tagButton {
    font-family:Helvetica, Arial, sans-serif;
    font-size: 0.7em;
    border-radius: 20px;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    margin: 0.5vh 0.2vh;    
    color:black !important;    
    padding: 1vh 3vh;
    cursor: pointer;   
    font-weight: 800;
    text-align: left;
}

.tagButton div {
    position:relative;
    left:9px;
    bottom:1em;
}

.delete {
    float:right;
    padding:3%;
    margin-left:3%;
    border-radius: 10px;
    border-width: 1;
    border-color: white;
    background-color: red;
}

.selected {
    background-color: #13A1C3;
    color:white !important;
}

.option {
    background-color: #FBCE44;
}

.tagButton:hover {
    background-color: #13A1C3;
    font-weight: 900;
    color:white !important;
}