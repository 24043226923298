.plus {
    border-radius: 20px;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    background-color: #FBCE44;
    font-size: 2em;
    width:90%;
    margin-right:auto;
    margin-left:auto;
    padding:1%;
    text-align: center;
}

.addData {
    border-width: 0.5px;
    border-style:solid;
    border-radius: 20px;;
    margin:5%;
    padding: 3%;
    
}

form {
    display:inline-block;
}

textAreaWrapper {

}

form input, textarea {
    margin:1%;
    border-radius: 20px;
    border-style: solid;
    text-align:center;
    padding:1%;
    width:70%;
    clear:both;    
}

