.appHeader {
    display:block;
    clear:both;
    text-align:center;
    font-size: 1.5vw;
    display: block;
}



h1.appHeader {
    clear:both;
    text-align: center;
}

.appHeader img {
    padding: 5vw 1vw 1vw;
    width:10vh;
}

.appFooter {
    display:block;
    clear:both;
    text-align:center;
    font-size: 3vw;
    display: block;
}


.appFooter p{
    font-size: 3vw;
}

h1.appFooter {
    clear:both;
    text-align: center;
}

.appFooter img {
    padding: 5vw 1vw 1vw;
    width:10vh;
}